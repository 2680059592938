import React, {createContext, useState} from "react"
import {defaultLanguage} from "../lib/language";

const LanguageContext = createContext({})

const LanguageProvider = ({children, language = null}) => {
    if (!language) {
        language = defaultLanguage;
    }

    const [currentLanguage, setCurrentLanguage] = useState(language);

    if (currentLanguage !== language) {
        setCurrentLanguage(language);
    }

    return (
        <LanguageContext.Provider
            value={{
                currentLanguage,
                setCurrentLanguage,
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
}
export {LanguageContext, LanguageProvider}

import React from "react";
import Container from "./Container";
import {Link} from "gatsby";
import Logo from "./Logo";
import {defaultLanguage, languages} from "../lib/language";
import {getContentUrl} from "../lib/helpers";
import LanguageSelector from "./LanguageSelector";

const DesktopHeader = (props) => {
    let {className, menu = [], language, translations = []} = props;

    if (!language) {
        language = defaultLanguage;
    }

    return (
        <div
            className={`inset-x-0 py-8 fixed z-50 bg-opaque-white-harder backdrop-blur font-heading h-32 ${className}`}>
            <Container>
                <div className='flex items-end mt-3'>
                    <Link to={language === defaultLanguage ? '/' : `/${language}`} className='w-130'>
                        <Logo
                            className='transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105' />
                    </Link>
                    <div className='flex-1 flex items-center'>
                        <div className="flex-1 flex items-center">

                            <nav className={`md:ml-auto font-heading`}>
                                <ul className='list-reset flex flex-col md:flex-row -mr-2 md:items-center text-sm'>
                                    {menu && menu.map((item, index) => (
                                        <li key={`menu-${index}`} className='md:ml-4'>
                                            <Link
                                                className='px-2 md:text-gray-700 hover:text-brand-red font-medium transition duration-200 ease-in-out py-1 rounded'
                                                activeClassName='bg-brand-red text-white md:text-white hover:text-white font-bold'
                                                activeStyle={{color: 'white!important'}}
                                                partiallyActive={false}
                                                to={item.content.isFrontPage ? '/' : getContentUrl(item.content.seo.slug.current, item.content.language || defaultLanguage)}>
                                                {item.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                        <div className='w-130'>
                            <ul className='list-reset flex items-center justify-end'>
                                <li className='mr-2 inline-flex'>
                                    <a href='https://www.facebook.com/teamisi' target='_blank'
                                       className='bg-brand-grey-lighter text-white flex items-center justify-center h-8 w-8 rounded-full inline-flex transition duration-500 ease-in-out hover:bg-brand-blue'>
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li className='inline-flex'>
                                    <a href='https://www.linkedin.com/company/isi-as'
                                       target='_blank'
                                       className='bg-brand-grey-lighter text-white flex items-center justify-center h-8 w-8 rounded-full inline-flex transition duration-500 ease-in-out hover:bg-brand-blue'>
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <LanguageSelector
                            current={language}
                            translations={translations}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default DesktopHeader;

import {createClient} from "@sanity/client"
import clientConfig from './client-config'

const projectId = clientConfig.sanity.projectId
const envDataset = clientConfig.sanity.dataset
const apiVersion = "2024-08-13"

export function getSanityPreviewClient(
    preview = null,
    previewDataset = envDataset
) {
    const client = createClient({
        projectId,
        dataset: previewDataset,
        apiVersion,
        useCdn: false, // to ensure data is fresh
        token: preview?.token,
    })

    return client
}

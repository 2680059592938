import no from './no'
import en from './en'
import {defaultLanguage} from "../lib/language";

export function translate(term, language) {
    if (language === 'no') {
        return no[term];
    }

    if (language === 'en') {
        return en[term];
    }

    return term;
}

import Dropdown from './Dropdown'
import {languages} from "../lib/language";
import {getContentUrl} from "../lib/helpers";

export default function LanguageSelector({translations, current, className}) {
    let currentLanguage = languages
        .find((lang) => lang.id === current);

    if (!translations.length) {
        return '';
    }
    return <div className={`flex ${className}`}>
        <Dropdown
            label={currentLanguage.name}
            items={translations.map(lang => ({
                label: lang.name,
                link: getContentUrl(lang.slug, lang.language),
                active: current === lang.language,
            }))}
        />
    </div>;
}

import React, {useState} from 'react'

export default function Collapsible({openByDefault = false, header = null, children}) {
    const [opened, setOpened] = useState(true);
    const onClick = (e) => {
        setOpened(!opened);
    }

    const openedIcon = () => {
        if(opened) {
            return (<i className="fa fa-chevron-up"></i>);
        }
        return (<i className="fa fa-chevron-down"></i>);
    }
    return (
        <>
            {
                header(
                    onClick,
                    openedIcon
                )
            }
            {opened && (
                <div>
                {children}
                </div>
            )}
        </>
    )
}

module.exports = {
    sanity: {
        projectId: process.env.GATSBY_SANITY_PROJECT_ID || 'fybyp6ub',
        dataset: process.env.GATSBY_SANITY_DATASET || 'v2',
        token: process.env.GATSBY_SANITY_READ_TOKEN || 'sk6mn9qYO97dqF7VHlFOv5Hh0qlkCSQfE66HzFoBRd89tXQJ2GepBsuodGMoB01mwBxt3iuurgD3rVPtjemB1TtezoKKxEWvmzAUhAcp3bx6zeUMWXFVIYF3j81qyvsyMjVuqxoJFz0JT1W37F5WATcPwojehaBzmYCDE5rBfWUEgq7SVbA0'
    },
    maps: {
        apiKey: process.env.GOOGLE_MAPS_KEY || 'AIzaSyAr38H-aUGvVLNYTu80IjN36VzsaDc0q4I'
    }
}

import React, {useContext} from 'react'
import DesktopHeader from "./HeaderDesktop";
import MobileHeader from "./HeaderMobile";
import {useStaticQuery, graphql} from 'gatsby'
import {defaultLanguage, languages} from '../lib/language';
import {CategoryContext} from "../context/CategoryContext";

const Header = ({className, context}) => {
    const {category, getCategories, getCurrentCategory} = useContext(CategoryContext);


    const data = useStaticQuery(graphql`
        query HeaderSettingsQuery {
            settings: allSanitySiteSettings {
                edges {
                    node {
                        language
                    }
                }
            },

            translations: allSanityTranslationMetadata {
                edges {
                    node {
                        _id
                        translations {
                            _key
                            _rawValue(resolveReferences: {maxDepth: 1})
                        }
                    }
                }
            }
        }
    `)

    const getPath = () => {
        let path = typeof window !== 'undefined' ? window.location.pathname : '';
        path = path.replaceAll('/', '')

        return path;
    }
    const isFrontPage = () => {
        let path = getPath();
        return path === '' || path === 'en'
    }
    let language = defaultLanguage;

    if (isFrontPage()) {
        language = getPath() === 'en' ? 'en' : 'no';
    } else if (context.language) {
        language = context.language;
    }

    let settings = data.settings.edges.filter(({node}) => {
        return node.language === language;
    })[0].node;


    let menu = [];
    let currentCategory = null;
    if (getCurrentCategory) {
        currentCategory = getCurrentCategory(language);
        if (currentCategory) {
            menu = currentCategory.menu;
        }
    }

    let categories = []
    if(getCategories) {
        categories = getCategories();
    }


    let items = [];

    if (isFrontPage()) {
        items.push({
            language: 'en',
            name: languages.find(lang => lang.id === 'en').name,
            slug: null,
        })
        items.push({
            language: 'no',
            name: languages.find(lang => lang.id === 'no').name,
            slug: null,
        })
    } else {
        data.translations.edges
            .map(({node}) => node)
            .filter(({translations}) => {
                return translations.filter((({_rawValue}) => {
                    return _rawValue?._id === context.id;
                })).length > 0;
            })
            .forEach(({translations}) => {
                translations.forEach(({_rawValue, _key}) => {
                    if (_rawValue) {
                        items.push({
                            language: _key,
                            name: languages.find(lang => lang.id === _key).name,
                            slug: _rawValue.seo ? _rawValue.seo.slug.current : null,
                        })
                    }
                })
            })
    }

    return (
        <>
            <DesktopHeader className={`hidden lg:block ${className}`} menu={menu} translations={items}
                           language={language} />
            <MobileHeader className={`block lg:hidden ${className}`} menu={menu} translations={items}
                          language={language} currentCategory={currentCategory} categories={categories} />
        </>
    )
}

export default Header

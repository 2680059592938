export const defaultLanguage = 'no';
export const languages = [
    {
        id: 'no',
        name: 'Norsk',
    },
    {
        id: 'en',
        name: 'English',
    }
];


export function getCurrentLanguage() {
    if(typeof window !== 'undefined') {
        let slug = window.location.pathname
            .split('/')
            .filter(i => !!i);

        if(languages.filter(l => l.id === slug[0]).length) {
            return languages.find(l => l.id === slug[0]).id;
        }
    }

    return defaultLanguage;
}

import {LiveQueryProvider} from "@sanity/preview-kit"
import React, {useContext} from "react"
import {getSanityPreviewClient} from "../../sanity"
import {PreviewContext} from "../context/PreviewContext"
import clientConfig from '../../client-config';

export default function SanityPreviewConnectorProvider({children}) {
    const {activePreview, previewContextData} = useContext(PreviewContext)
    const client = getSanityPreviewClient(
        {token: clientConfig.sanity.token},
        previewContextData?.previewDataset
    )

    if (!activePreview) {
        // Return the regular children with no draft documents
        return <>{children}</>
    }

    // Preview mode enabled
    return (
        <LiveQueryProvider client={client}>
            {children}
        </LiveQueryProvider>
    )
}

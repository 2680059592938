export default {
    'privacy_url': '/en/privacy',
    'privacy_statement': 'Privacy',
    'follow_us': 'Follow us',
    'name': 'Name',
    'email': 'E-mail Address',
    'phone': 'Phone',
    'write_message_here': 'Write your message here.',
    'send': 'Send Message',
    'by_sending': 'By sending your enquiry you accept that iSi AS stores your contact details.',
    'published': 'Published',
    'latest_from_blog': 'Latest from the blog',
    'thank_you_contact': 'Thank you for your enquiry.',
    'respond_asap': 'We will respond as soon as possible.',
    'latest_news': 'Latest news',
}

import React, {createContext, useState} from "react"
import {useStaticQuery, graphql} from "gatsby";
import {defaultLanguage, getCurrentLanguage, languages} from "../lib/language";

const CategoryContext = createContext()

const CategoryProvider = ({children, language = defaultLanguage}) => {
    const [category, setCategory] = useState(null)


    const data = useStaticQuery(graphql`
        {
            defaults: allSanityContentCategory {
                edges {
                    node {
                        id
                        title
                        language
                        isDefault
                        slug {
                            current
                        }
                        menu {
                            title
                            content {
                                isFrontPage
                                language
                                seo {
                                    slug {
                                        current
                                    }
                                }
                            }
                        }
                    }
                }
            },
        }
    `).defaults.edges.map(n => n.node);

    const [categories, setCategories] = useState(data)

    let defaults = categories.filter(c => c.isDefault || false)

    const getCategories = (lang = language) => {
        return categories.filter(c => c.language === getCurrentLanguage()).sort((a, b) => {
            return a.isDefault ? -1 : 0;
        })
    };

    const getCurrentCategory = (lang = language) => {
        if (typeof window !== "undefined") {
            let slug = window.location.pathname
                .split('/')
                .filter(i => !!i);

            // first part of url is a language id
            if (languages.map(l => l.id).includes(slug[0])) {
                slug.shift()
            }

            if (category) {
                if (category.length === 1) {
                    return category[0];
                }
                let currentCategory = category.filter(cat => cat.slug.current === slug[0])
                if (currentCategory.length) {
                    return currentCategory[0];
                }
            }
        }

        let defaultItem = defaults.filter(i => i.language === language);

        return defaultItem.length ? defaultItem[0] : null;
    }

    return (
        <CategoryContext.Provider
            value={{
                category, setCategory, getCurrentCategory, defaults, getCategories
            }}
        >
            {children}
        </CategoryContext.Provider>
    );
}
export {CategoryContext, CategoryProvider}

import React, {useContext, useState} from "react";
import Container from "./Container";
import {Link} from "gatsby";
import Logo from "./Logo";
import DecalThree from "../assets/decal3.svg";
import {getContentUrl} from "../lib/helpers";
import {defaultLanguage} from "../lib/language";
import LanguageSelector from "./LanguageSelector";
import Collapsible from "./Collapsible";
import {CategoryContext} from "../context/CategoryContext";

const Menu = ({show, close, menu = [], translations = [], categories = []}) => {
    const {category, getCategories, getCurrentCategory} = useContext(CategoryContext);
    const currentCategory = getCurrentCategory();

    if(show) {
        return (
            <div
                className={`z-[99999999] fixed mt-24 h-screen w-full inset-x-0 top-0 bg-gradient-to-b via-brand-blue from-brand-blue transition duration-200 ease transform opacity-100 translate-y-0`}>
                <div className='overflow-y-scroll h-full '>
                    {categories && categories.map(category => (
                        <nav className='px-10 pt-10 relative z-[99999]'>
                            <Collapsible openByDefault={currentCategory.id === category.id}
                                         header={(onCollapse, openedIcon) => (
                                             <h3 className='flex font-heading text-white text-xl font-bold mb-4'>
                                                 {category.title}

                                                 {/*<button className="ml-auto pointer" onClick={onCollapse}>*/}
                                                 {/*    {openedIcon()}*/}
                                                 {/*</button>*/}
                                             </h3>
                                         )}>
                                <ul className='font-heading list-reset text-sm'>
                                    {category.menu && category.menu.map((item, index) => (
                                        <li key={`menu-${index}`} className='mb-4'>
                                            <Link
                                                onClick={close}
                                                className='text-white text-base font-medium text-brand-grey-dark hover:font-semibold'
                                                activeClassName='font-semibold'
                                                partiallyActive={true}
                                                to={item.content.isFrontPage ? '/' : getContentUrl(item.content.seo.slug.current, item.content.language || defaultLanguage)}>
                                                {item.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </Collapsible>
                        </nav>
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <></>
        )
    }
}

const MobileHeader = ({menu, translations, className, language = defaultLanguage, categories = []}) => {
    const [showNav, setShowNav] = useState(false);

    const toggleNav = () => setShowNav(prev => !prev);

    const closeMenu = () => setShowNav(false);
    const closeSearch = () => setShowSearch(false);

    return (
        <div className={className}>
            <div
                className={`inset-x-0 fixed z-50 bg-opaque-white-harder backdrop-blur font-heading h-24 flex items-end pb-4`}>
                <Container className='z-50 relative'>
                    <div className='flex items-center'>
                        <Link to={language === defaultLanguage ? '/' : `/${language}`} onClick={closeMenu}
                              className='mr-8'>
                            <Logo large
                                  className='transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105' />
                        </Link>
                        <div className="ml-auto flex items-center">

                            <LanguageSelector
                                current={language}
                                translations={translations}
                                className={'mr-4'}
                            />

                            <button className='text-brand-blue text-2xl' onClick={toggleNav}>
                                <i className="fa fa-bars" />
                            </button>
                        </div>
                    </div>
                </Container>
            </div>
            <Menu menu={menu} show={showNav} close={() => {
                closeMenu();
            }} categories={categories} />
        </div>
    );
};

export default MobileHeader;

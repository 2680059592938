export default {
    'privacy_url': '/personvern',
    'privacy_statement': 'Personvernerklæring',
    'follow_us': 'Følg oss',
    'name': 'Navn',
    'email': 'Epostadresse',
    'phone': 'Telefon',
    'write_message_here': 'Skriv meldingen din her.',
    'send': 'Send Melding',
    'by_sending': 'Ved å sende inn henvendelse tillater du at iSi AS lagrer dine kontaktdata.',
    'published': 'Publisert',
    'latest_from_blog': 'Siste fra bloggen',
    'thank_you_contact': 'Takk for henvendelsen.',
    'respond_asap': 'Vi svarer så fort vi har mulighet.',
    'latest_news': 'Siste nyheter',
}
